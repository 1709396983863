// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-copied-message-js": () => import("../src/pages/home/copiedMessage.js" /* webpackChunkName: "component---src-pages-home-copied-message-js" */),
  "component---src-pages-home-home-js": () => import("../src/pages/home/home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-index-js": () => import("../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-step-four-js": () => import("../src/pages/home/stepFour.js" /* webpackChunkName: "component---src-pages-home-step-four-js" */),
  "component---src-pages-home-step-one-js": () => import("../src/pages/home/stepOne.js" /* webpackChunkName: "component---src-pages-home-step-one-js" */),
  "component---src-pages-home-step-three-js": () => import("../src/pages/home/stepThree.js" /* webpackChunkName: "component---src-pages-home-step-three-js" */),
  "component---src-pages-home-step-two-js": () => import("../src/pages/home/stepTwo.js" /* webpackChunkName: "component---src-pages-home-step-two-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-play-index-js": () => import("../src/pages/play/index.js" /* webpackChunkName: "component---src-pages-play-index-js" */),
  "component---src-pages-play-play-js": () => import("../src/pages/play/play.js" /* webpackChunkName: "component---src-pages-play-play-js" */),
  "component---src-pages-practice-index-js": () => import("../src/pages/practice/index.js" /* webpackChunkName: "component---src-pages-practice-index-js" */),
  "component---src-pages-practice-practice-js": () => import("../src/pages/practice/practice.js" /* webpackChunkName: "component---src-pages-practice-practice-js" */),
  "component---src-pages-results-index-js": () => import("../src/pages/results/index.js" /* webpackChunkName: "component---src-pages-results-index-js" */),
  "component---src-pages-results-results-js": () => import("../src/pages/results/results.js" /* webpackChunkName: "component---src-pages-results-results-js" */)
}

